//
//

// Vendor imports

// import $ from 'jquery'
import 'Node/bootstrap' 
import ScrollReveal from 'Node/scrollreveal'
import matchHeight from './vendor/matchheight.js'

import { countdown } from './vendor/countdown.js'


// form imports 
import { multiStepFilter } from "./_modules/forms/_multi-step-filter.js"
import { passwordReveal } from "./_modules/forms/_password-hide-reveal.js"
import { customSelect } from "./_modules/forms/_custom-select.js"

// navigation imports
import { offCanvasNavigation } from "./_modules/navigation/_offCanvasNav.js"
import { shrinkNav } from "./_modules/navigation/_shrink-nav.js"

import { dismissNotification } from "./_modules/_dismiss-notification.js"

import { slideRevealer  } from "./_modules/_slideToggle.js"
import { detectPageSection } from "./_modules/_detectPageSection.js"
import { pwccNav } from "./_modules/_pwcc-nav.js"
import "./_modules/_scrollToSection.js"


import "./_modules/_schedule.js"
import "./_modules/_map-selector.js"





const filterLocations = new multiStepFilter();

// Init functions
// const MainNav = new offCanvasNavigation;

ScrollReveal().reveal('.reveal', {
	distance: '40px',
	easeing: 'ease-in-out',

	viewOffset : {
		top: 140
	}
});


ScrollReveal().reveal('.flex-row .pwcc-section', {
	distance: '30px',
	easeing: 'ease-in-out',
	origin: 'left'

});


$(function() {
	$('.dont-say-oh').removeClass('fade-out');
});


countdown("May 18, 2019 17:00:00");

//pwcc
detectPageSection('[data-page-section]', '#page-section-wrap', '#pwcc-collapsable-nav');

